import React from 'react'
import Transition from '../components/transition'
import { node, object } from 'prop-types'

const Wrapper = ({ children, location }) => (
  <Transition location={location}>{children}</Transition>
)

Wrapper.propTypes = {
  children: node,
  location: object
}

export default Wrapper
