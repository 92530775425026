import './src/styles/normalize.css'
import './src/styles/components.css'
import './src/styles/global.css'
import './src/styles/tooltip.css'
import './src/styles/common.css'
import './src/styles/lightbox.css'
// or:
// require('./src/styles/global.css')

export const onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, '')
  })
}
